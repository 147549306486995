<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <!--begin::Subheader-->
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <div class="container">
        <div
          class="alert alert-custom alert-white alert-shadow gutter-b"
          role="alert"
        >
          <div class="alert-text">
            <div class="nav nav-dark border-bottom">
              <a target="_blank" class="nav-link border-bottom border-primary"
                >我的采购</a
              >
              <a target="_blank" class="nav-link">我的销售</a>
            </div>
          </div>
        </div>
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-body">
              <div class="row">
                <div class="col-md-4 p-3 text-center" v-on:click="toMaterial">
                  <div>
                    <img class="symbol-label" :src="cai" alt="" />
                  </div>
                  <span style="font-weight: bold;">板材超市</span>
                </div>
                <div class="col-md-4 p-3 text-center">
                  <div>
                    <img class="symbol-label" :src="xiao" alt="" />
                  </div>
                  <span class="text-muted">订单超市</span>
                </div>
                <div class="col-md-4 p-3 text-center">
                  <div>
                    <img class="symbol-label" :src="she" alt="" />
                  </div>
                  <span class="text-muted">产能超市</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    cai() {
      return process.env.BASE_URL + "media/test/icloud/cai.png";
    },
    xiao() {
      return process.env.BASE_URL + "media/test/icloud/xiao.png";
    },
    xin() {
      return process.env.BASE_URL + "media/test/icloud/xin.png";
    },
    company() {
      return process.env.BASE_URL + "media/test/icloud/company.png";
    },
    jin() {
      return process.env.BASE_URL + "media/test/icloud/jin.png";
    },
    she() {
      return process.env.BASE_URL + "media/test/icloud/she.png";
    }
  },
  methods: {
    toMaterial: function() {
      this.$router.push("/dashboard/materialList");
    }
  }
};
</script>
